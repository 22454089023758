.info {
    background-color: $dark2;
    background-blend-mode: multiply;
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    transition: all 0.2s ease;
    position: relative;
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        z-index: 100;
        width: 100%;
        height: 100%;
        .logo {

            width: 30%; }
        .client-name {
            color: $white;
            font-size: 5vw;
            font-weight: 700;
            transition: all 0.2s ease;
            text-align: center;
            line-height: 1em;
            margin-top: 0.5em;
            margin-bottom: 0.25em; }
        .subtitle {
            text-transform: uppercase;
            font-weight: 400;
            letter-spacing: 0.4em;
            color: $white;
            font-size: 1.5em; } } }
