@import "../../../styles";

.recorder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    #recording {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        display: none;
        .text {
            display: flex;
            align-items: center;
            justify-content: center;

            margin-bottom: 1em;
            svg {
                color: $red;
                font-size: 2em; }

            &:first-child {
                svg {
                    font-size: 1em; } } }
        #recording-text {
            width: 100%;
            padding: 0.5em;
            background-color: $color1B;
            margin: 1em 0;
            font-size: 1.25em;
            svg {
                margin-right: 0.25em; } } }

    .buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .audio {
            width: 100%; }

        button {
            background-color: $color1;
            color: $grey1;
            width: 100%;
            height: 4em;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.4s ease;
            padding: 0 0.5em;
            svg {
                font-size: 1.5em; }
            &:first-child {
                border-radius: 0.5em 0 0 0.5em; }
            &:last-child {
                border-radius: 0 0.5em 0.5em 0; }
            &:hover {
                opacity: 0.9; } }
        #stop {
            svg {
                font-size: 2.5em; } }
        .active {
            opacity: 0.9; } } }

#recording {}
.visible {
    display: flex!important; }
