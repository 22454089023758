@import "../../../styles";

#user-options {
    position: fixed;
    top: 1em;
    right: 1em;
    z-index: 9999;
    .chakra-menu__icon-wrapper {
        svg {
            font-size: 2em; } }
    .menu-list-4 {
        z-index: 9999!important; } }

