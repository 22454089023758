@import "../../../styles";

.help-button {
    position: fixed;
    background-color: transparent;
    height: 3em;
    width: 3em;
    border: 2px solid $grey3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey3;
    top: 2em;
    right: 2em;
    transition: all 0.4s ease;
    z-index: 999;
    cursor: pointer;
    &:hover {
        border-color: $grey3;
        color: $color2; }
    svg {
        font-size: 1.5em; }

    @media screen and ( max-width: 550px ) {
        right: 1em; }

    @media screen and ( max-height: 600px ) {
        display: none; } }
