@import "../../../styles";

#view-story-modal {
    .card {

        .label {
            text-transform: uppercase;
            color: $dark3; }
        .title {
            margin-top: -.25em;
            font-size: 1.5em;
            margin-bottom: 1em; }
        .answers {
            .label {
                margin-bottom: 0.5em; }
            .answer {
                background-color: $grey2;
                margin-bottom: 1em;
                padding: 0.75em;
                border-radius: 0.25em; } } } }
