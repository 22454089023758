.form {
    background-color: $grey1;
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    transition: all 0.2s ease;
    z-index: 1000;
    min-height: 25em;

    .wrapper {
        width: 70%;
        max-width: 600px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        transition: all 0.4s ease;
        color: $dark1;
        position: relative;
        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.8em;
            font-weight: 500;
            margin-bottom: 1em; }
        input {
            border-color: $grey3;
            svg {
                color: $dark1; }
            &::placeholder {
                color: $dark1; } }
        .forgot-pw {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.4s ease;
            font-size: 1.15em;
            &:hover {
                color: $color1; } }
        button {
            margin-top: 2em;
            border: 2px solid $color1;
            background-color: $color1;
            &:hover {
                background-color: transparent;
                color: $color1; } } } }

.input-error {
    border-color: $red!important; }

.error {
    margin-top: 0.5em;
    font-size: 1em;
    color: $red;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -3em;
    font-size: 1.2em;
    font-weight: 600;
    &:hover {
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden; } }
