#error404 {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .salve-logo {
        width: 10vw;
        height: 10vw;
        background: rgb(196,156,249);
        background: linear-gradient(90deg, rgba(196,156,249,1) 0%, rgba(102,0,255,1) 100%);
        border-radius: 50%;
        .sad-face {
            opacity: 0.25;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-image: url("https://www.iconpacks.net/icons/2/free-sad-face-icon-2691-thumb.png"); } }
    .title {
        margin-top: 0em;
        font-size: 5vw;
        font-weight: 600; } }
