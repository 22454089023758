@import "../../styles/index";
@import "./ClientInfo";
@import "./Form";

.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    background-color: $dark2;
    overflow: hidden;

    // General Media Queries
    @media screen and ( max-width: 1100px ) {
        .info {
            .wrapper {
                .client-name {
                    font-size: 8vw; } } }
        .form {
            .wrapper {
                width: 85%; } } }
    @media screen and ( max-width: 900px ) {
        flex-direction: column-reverse;
        .info {
            flex: 0.4;
            .wrapper {
                .client-name {
                    font-size: 4em; } } }
        .form {
            flex: 0.6;
            border-radius: 1.4em 1.4em 0 0;
            .wrapper {
                width: 85%; } } }
    @media screen and ( max-width: 650px ) {

        .info {
            flex: 0.35;
            .wrapper {

                .logo {
                    width: 40%; }
                .client-name {
                    font-size: 15vw; }
                .subtitle {
                    font-size: 1.4em; } } }
        .form {
            flex: 0.65;
            .wrapper {
                margin-top: -5em; } } } }

// Active Input Class
.active-f {
    transition: all 0.2s ease;
    border: 2px solid $color1!important; }

// Shake Animation & Class
.shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden; }

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0); }

    20%, 80% {
        transform: translate3d(2px, 0, 0); }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0); }

    40%, 60% {
        transform: translate3d(4px, 0, 0); } }
