@import "./index";

//Fonts
@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600;800&display=swap');

body {
    font-family: "Poppins", sans-serif; }

//Scrollbar
::-webkit-scrollbar {
    width: 25px;
    margin-top: 0; }

::-webkit-scrollbar-thumb {
    width: 25px;
    border-left: 6px solid rgba(0, 0, 0, 0);
    border-right: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05); }

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none; }

::-webkit-scrollbar-corner {
    background-color: transparent; }

.container {
    margin: 0 auto;
    width: 100%;
    max-width: 90em;
    padding: 0 2em;
    @media screen and ( max-width: 768px ) {
        padding: 0 1em; } }

.App {
    overflow-x: hidden;
    width: 100%; }
