@import "../../../styles";

.stories-available {
    .available {
        background-color: transparent;
        stroke-dasharray: 2em;
        border: 2px solid $grey1;
        border-radius: 0.2em;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23CCCCCCFF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 15px;
        cursor: pointer; }

    @media screen and ( max-width: 420px ) {
        .css-r6z5ec {
            z-index: 99999;
            font-size: 1.35em; } } }


