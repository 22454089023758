@import "../../../styles/index";

.story-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
    padding-top: 1em;
    position: relative;

    //Indicator
    .indicator {
        width: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: 1em;
        position: absolute;
        height: 100%;
        left: -2em;
        .indicator-bar {
            height: 50%;
            width: 1px;
            background-color: $grey2; }
        #indicator-circle {
            position: absolute;
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            border: 2px solid $grey2;
            background-color: $grey1;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                opacity: 0;
                font-size: 0.8em;
                color: $green; } } }

    //Story Content
    .item-content {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 1.5em;
        stroke-dasharray: 2em;
        border: 2px solid $grey1;
        border-radius: 0.2em;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23CCCCCCFF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 15px;
        transition: all 0.4s ease;
        .number {
            margin-right: 0.5em; }
        &:hover {
            transform: scale(1.01);
            cursor: pointer; } }

    &:first-child {
        .indicator {
            .indicator-bar {
                &:first-child {
                    opacity: 0; } } } }
    &:last-child {
        .indicator {
            .indicator-bar {
                &:last-child {
                    opacity: 0; } } } }

    @media screen and ( max-width: 1200px ) {
        .indicator {
            left: 0;
            #indicator-circle {
                width: 1em;
                height: 1em; } }
        .item-content {
            margin-left: 2em; } } }

//Answered Question
.filled {
    .indicator {
        #indicator-circle {
            border-color: $greenB;
            background-color: $greenB;
            svg {
                opacity: 1; } } }
    .item-content {
        background-image: none;
        background-color: #fff;
        border-radius: 0;
        position: relative;
        overflow: hidden;
        box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
        &::before {
            content: "";
            position: absolute;
            width: 2em;
            height: 2em;
            background-color: $grey1;
            top: -1em;
            right: -1em;
            transform: rotate(45deg);
            z-index: 1; }
        &::after {
            content: "";
            position: absolute;
            width: 2em;
            height: 2em;
            background-color: $grey2;
            top: -0.5em;
            right: -0.5em;
            z-index: 0; }
        .number {
            display: none; }
        .question {
            font-size: 0.95em;
            color: $grey3;
            font-style: italic; }
        .answer {
            margin-top: 1em; } }

    //Media Queries
    @media screen and ( max-width: 420px ) {
        .item-content {
            padding: 1em; } } }
