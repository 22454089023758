
//Colors
$color1: #a062a5;
$color1D: #724275;
$color1B: #f3dcf5;
$color2: #ec8e8e;
$color2B: #ec8e8e;
$grey1: #f3f3f3;
$grey2: #dddcdc;
$grey3: #adadad;
$dark1: #353535;
$dark2: #222;
$dark3: #111;
$white: #ffffff;
$green: #7ad169;
$greenB: #c9ffbe;
$red: #ff5e5e;
$redB: #ffdcdc;
$lightblue: #4cb5f1;
