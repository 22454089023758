@import "../../styles/index";

#dashboard-admin {
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    background-color: $grey1;
    .header {
        height: fit-content;
        padding: 1em 0;
        background-color: $dark1;
        color: $grey1;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between; }
        img {
            width: 3.5em; }

        .buttons {
            display: flex;
            #user-options {
                position: relative;
                top: 0;
                left: 0;
                color: $dark1; }
            .nav-item {
                font-size: 1.25em;
                margin-right: 1em;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center; } } }
    .content {
        height: 100%;
        padding: 2em;
        overflow-y: auto; }

    @media screen and ( max-width: 550px ) {
        .content {
            padding: 1em;
            .container {
                padding: 0; } } }

    @media screen and ( max-width: 420px ) {
        .content {
            padding: 0; } } }
