@import "../../styles/index";
@import "./StoryItem/";

#create-story {
    height: 100%;
    background-color: $grey1;
    .progress-bar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100; }
    .header {
        height: 20vh;
        background-color: $grey2;
        position: relative; }
    .content {
        padding: 1.5em 0;
        background-color: $grey1;
        min-height: 80vh;
        min-height: 100%;
        .container {
            height: fit-content;
            min-height: 100%;
            .title {
                font-size: 1.4em;
                font-weight: 600;
                margin-bottom: 0.5em; }
            .subtitle {
                line-height: 1.2em;
                .click {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .help-button {
                        position: relative;
                        top: 0;
                        right: 0;
                        margin: 0.75em;
                        font-size: 0.75em; } } }
            .title-input {
                margin-top: 1em;
                .label {
                    margin-bottom: 0.5em;
                    font-size: 1.25em;
                    color: $lightblue; } }

            .items {
                display: flex;
                flex-direction: column;
                margin-top: 2em;
                height: 100%; }

            .recorder-container {
                width: 100%;
                max-width: 50vw;
                margin: 2rem auto;
                background-color: #dadada;
                padding: 1rem;
                border-radius: 0.5rem;
                @media screen and ( max-width: 1024px ) {
                    max-width: none; } }

            .buttons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 2em 0;
                width: 100%;
                .wrapper {
                    display: flex;
                    width: 100%; }
                #delete-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border: 1px solid $red;
                    border-radius: 0.5em;
                    width: 5em;
                    height: 3.4em;
                    margin-right: 1em;
                    transition: all 0.4s ease;
                    &:hover {
                        background-color: $red;
                        svg {
                            color: $grey1; } }
                    svg {
                        font-size: 1.5em;
                        color: $red; } }
                button {
                    width: 100%;
                    min-width: 4em;
                    margin-left: 1em;
                    height: 3em;
                    margin-top: 0;
                    svg {
                        font-size: 1.5em;
                        margin-right: 0.25em; }

                    &:first-child {
                        border-color: $dark1; }
                    &:last-child {
                        background-color: $dark1;
                        color: white; } } } } }

    //Media Queries
    @media screen and ( max-width: 768px ) {
        .content {
            .title {
                font-size: 1.2em; }
            .items {
                margin-top: 1em; } } }

    @media screen and ( max-width: 550px ) {


        .content {
            .container {
                .items {
                    margin-top: 1em;
                    .story-item {
                        padding-top: 0.5em;
                        .item-content {
                            margin-left: 1.5em; } } }
                .buttons {
                    margin-top: 3em;
                    flex-wrap: wrap;
                    button {
                        margin-top: 0.5em;
                        width: 100%;
                        margin-left: 0; } } } } } }

.no-scroll {
    position: absolute;
    height: 100vh;
    overflow: hidden; }
