@import "../../styles/index";

#profile {
    height: 100%;
    background-color: $grey1;
    position: relative;
    #create-story-button-mb {
        display: none; }
    .header {
        height: 25vh;
        background-color: $color1;
        position: relative;
        background-image: url("https://www.transparenttextures.com/patterns/soft-circle-scales.png");
        background-size: 256px 56px;
        .container {
            height: 100%;
            align-items: flex-end;
            justify-content: space-between;
            display: flex;
            padding-bottom: 1em;

            #create-story-button {

                button {

                    &:hover {
                        color: #171923;
                        background-color: $grey1; } } }
            .title {
                font-size: 4vw;
                line-height: 4vw;
                color: $grey1;
                span {
                    font-weight: 700; } } } }
    .content {
        padding: 1.5em 0;
        background-color: $grey1;
        min-height: 75vh;
        height: 100%;
        padding-bottom: 8em;
        .container {
            height: 100%;
            section {
                margin-bottom: 2em;
                .title {
                    font-size: 1em;
                    color: $grey3;
                    font-weight: 600;
                    margin-bottom: 0.5em;
                    text-transform: uppercase; }
                .story {
                    padding: 0 1.5em;
                    height: 5em;
                    margin-bottom: 1em;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .story-info {
                        .title {
                            font-size: 1.2em;
                            line-height: 1.2em;
                            margin-bottom: 0.5em;
                            text-transform: none;
                            color: $dark1; }
                        .description {
                            font-size: 1em; } }
                    .buttons {
                        button {
                            svg {
                                font-size: 1.5em; } } } }

                .pending {
                    background-color: $color1B;
 } } } }                    // border: 2px solid $color1

    .empty {
        background-color: $grey2;
        padding: 0 1.5em;
        height: 4em;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 0.25em;
        color: $dark3;
        font-weight: 600;
        font-size: 1.25em; }

    //Media Queries
    @media screen and ( max-width: 1024px ) {
        .header {
            .container {
                .title {
                    font-size: 2em; } } } }

    @media screen and ( max-width: 768px ) {
        #create-story-button {
            display: none; }
        #create-story-button-mb {
            display: block;
            position: fixed;
            bottom: 1em;
            z-index: 99;
            width: 100%;
            left: 0;
            padding: 0 1em;
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 6em;
                background: rgb(243,243,243);
                background: linear-gradient(0deg, rgba(243,243,243,1) 0%, rgba(255,255,255,0) 100%);
                left: 0;
                bottom: 0;
                z-index: -100; }
            button {
                position: relative;
                width: 100%;
                &:hover {
                    background-color: #171923; } } }

        .content {
            .title {
                font-size: 1.2em; }
            .container {

                section {
                    .story {
                        padding: 1em; } } } } }

    @media screen and ( max-width: 420px ) {
        .content {
            .container {
                section {
                    .story {
                        margin-bottom: 0.5em; } } } } } }
