@import "../../../styles";

#back-button {
    position: absolute;
    top: 2em;
    left: 0;
    font-size: 1em;
    color: $dark1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: none;
    padding: 0.5em 1em;
    border-radius: 0 0.5em 0.5em 0;
    svg {
        font-size: 2em; }
    @media screen and ( max-width: 768px ) {
        svg {
            margin-left: -0.35em; } } }
